<template>
    <section class="mb-10">   
        <v-layout justify-space-around justify-center class="contact-layout">
            <v-flex xs9 sm9 offset-sm0 class="" align="center" justify="center" >
                    <v-card flat tile class="mt-4" color=""  >
                             <v-row  class="ma-0">
                                 <v-col cols="12" lg="8" md="8" sm="12" xs="12" class="pa-1" >
                                     <BlogContent :posts="posts" />
                                    <v-pagination v-if="posts !== null" v-model="currentPage"  class="zamu-pagination mt-5" :length="pagination.lastPage" :total-visible="7" ></v-pagination>
                                      
                                 </v-col>
                                 <v-col cols="12" lg="3" md="3" sm="12" xs="12" class="pa-1 ml-5">
                                    <v-card flat tile color="" class="pa-0">
                                      <h3 width="30%" class="categories-title zamu-heading" flat tile>
                                            categories
                                      </h3>
                                        <v-card tile flat :color="`${zamuGrey}`" height="7" class="justify-left  mb-1 transact-underline " width="46%"></v-card>
                                        <BlogCategories />
                                    </v-card>
                                     <v-card flat tile color="" class="mt-5">
                                          <v-card width="30%" class="categories-title zamu-heading" flat tile>
                                            highlights
                                           </v-card>
                                           <v-card tile flat :color="`${zamuGrey}`" height="7" class="justify-left mt-1 mb-1 transact-underline " width="46%"></v-card>
                                     </v-card>
                                 </v-col>
                             </v-row>
                    </v-card>
            </v-flex>
        </v-layout>
    </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';

export default {
    name: 'BlogHolder',
    props: ['posts', 'pagination'],

     components: {
      BlogContent: () => import('./blogContent'),    
      BlogCategories: () => import('./blogCategories'),      

     },
     data () {
         return {
             currentPage: 1,
         };
     },
    mixins: [colorMixin],
    watch: {
        async currentPage(page) {
          this.$emit('currentPage', page);
        },
    },


}
</script>

<style>

</style>